const {renderTable} = require('./table-render.js')
const {renderChart} = require('./chart-render.js')

const renderScoresaberData = (resJson) => {
  try {
    const tableRows = resJson.tableRows
    console.log('tableRows:')
    console.table(tableRows)
    document.getElementById('skillModel').innerText = resJson.skillModelStr

    renderTable(tableRows)
    renderChart(tableRows)

    //Hide loading indicators
    const loadingIndicators = document.getElementsByClassName('ring-loader')
    for (let i = 0; i < loadingIndicators.length; i++) {
      loadingIndicators[i].style.display = 'none'
    }
  } catch (e) {
    console.error(e)
    //Hide loading indicators
    const loadingIndicators = document.getElementsByClassName('ring-loader')
    for (let i = 0; i < loadingIndicators.length; i++) {
      loadingIndicators[i].innerHTML = '<p style="color: red;">Fetch Error: Reload Page</p>'
    }
  }
}

const setLoadingIndicators = (text) => {

}

module.exports.renderScoresaberData = renderScoresaberData
module.exports.renderScoresaberData = renderScoresaberData