const {sortJsonByKey} = require('./sort.js')
let ascending = false

const tableFromSongs = (songs) => {
  // console.log('songs:' + JSON.stringify(songs))
  let htmlRowsString = ''
  songs.forEach(function(song){
    let rowString = `<tr>\n<th scope="col"> ${song.songName} </th>\n`
    const keysToSkip = ['x', 'y', 'songName']
    for (let [key, value] of Object.entries(song)) {
      if(typeof value === 'number')
        value = value.toFixed(2)
      if(keysToSkip.indexOf(key) === -1)
        rowString += `<td data-title="${key}"> ${value} </td>\n`
    }
    rowString += '</tr>\n'
    htmlRowsString += rowString
  })
  // console.log('htmlRowsString:' + JSON.stringify(htmlRowsString))
  return htmlRowsString
}

const renderTable = (tableRowsJson) => {
  const sortedTableRowsAsPOJO = sortJsonByKey(tableRowsJson, 'predictedPPGrowth', ascending)
  const htmlRowsString = tableFromSongs(sortedTableRowsAsPOJO)

  document.getElementById('mainTableBody').insertAdjacentHTML('beforeend', htmlRowsString)

  //Gather th elements
  const thElements = document.getElementsByTagName('th')
  const camelCase = (str) => {
    const noSpaces = str.replace(/\s/g, '')
    const firstLetter = noSpaces.substring(0, 1).toLowerCase()
    return firstLetter + noSpaces.substring(1)
  }

  //Make th elements sort on click
  for (const th of thElements) {
    th.onclick = () => {
      ascending = !ascending
      const resortedTableRowsAsPOJO = sortJsonByKey(tableRowsJson, camelCase(th.innerText), ascending)
      const htmlRowsString = tableFromSongs(resortedTableRowsAsPOJO)
      document.getElementById('mainTableBody').innerHTML = ''
      document.getElementById('mainTableBody').insertAdjacentHTML('beforeend', htmlRowsString)
    }
  }
}

module.exports.renderTable = renderTable
module.exports.tableFromSongs = tableFromSongs