import Chart from 'chart.js'
import 'chartjs-plugin-zoom'
import 'chartjs-plugin-trendline'

const initialBlockSideLength = 15
const initialMinX = 100
const initialMaxX = 500
const blueBlock = new Image(initialBlockSideLength, initialBlockSideLength)
blueBlock.src = 'blue_block_point.png'
const redBlock = new Image(initialBlockSideLength, initialBlockSideLength)
redBlock.src = 'red_block_point.png'
Chart.defaults.global.elements.point.pointStyle = redBlock

const zoomHandler = ({chart}) => {
  const initialVisibleX = initialMaxX - initialMinX
  const currentMinX = chart.config.options.scales.xAxes[0].ticks.min
  const currentMaxX = chart.config.options.scales.xAxes[0].ticks.max
  const currentVisibleX = currentMaxX - currentMinX
  const scaledBlockSideLength = (initialBlockSideLength - 1 + ((initialVisibleX / currentVisibleX) * 2))

  // chart.config.data.datasets[0].pointHitRadius = scaledBlockSideLength * 0.3
  redBlock.width = scaledBlockSideLength
  redBlock.height = scaledBlockSideLength
  blueBlock.width = scaledBlockSideLength
  blueBlock.height = scaledBlockSideLength
}

const hoverHandler = (event, [point]) => {
  if (point) {
    window.myScatter.data.datasets[0].pointStyle = []
    window.myScatter.data.datasets[0].pointStyle[point._index] = blueBlock
    window.myScatter.update()
  }
}

const generateTooltipLabel = (tooltipItem, data) => {
  const song = data.datasets[0].data[tooltipItem.index]
  const keysToSkip = ['x', 'y']

  let rowStrings = []
  for (let [key, value] of Object.entries(song)) {
    if(typeof value === 'number')
      value = value.toFixed(2)
    if(keysToSkip.indexOf(key) === -1)
      rowStrings.push(`${key}: ${value}`)
  }
  return rowStrings
}

module.exports.renderChart = (tableRows) => {
  console.log(tableRows)
  const chartConfig = {
    type: 'scatter',
    data: {
      datasets: [{
        data: tableRows,
        label: 'Player Accuracy over Song Difficulty',
        xAxisID: 'x-difficulty',
        yAxisID: 'y-accuracy',
        pointStyle: [],
        trendlineLinear: {
          style: "#3e95cd",
          lineStyle: "line",
          width: 1
        }
      }]
    },
    options: {
      pan: {
        enabled: true,
        mode: 'xy',
      },
      zoom: {
        enabled: true,
        drag: false,
        mode: 'xy',
        speed: 0.2,
        onZoom: zoomHandler
      },
      scales: {
        xAxes: [{
          id: 'x-difficulty',
          type: 'linear',
          scaleLabel: {
            display: true,
            labelString: 'Difficulty (biggest possible pp)',
          },
          ticks: {
            min: initialMinX,
            max: initialMaxX,
          },
          gridLines: {
            color: 'rgba(255,255,255,0.1)',
            zeroLineColor: 'rgba(255,255,255,0.1)',
            zeroLineWidth: 3,
          },
        }],
        yAxes: [{
          id: 'y-accuracy',
          type: 'linear',
          ticks: {
            min: 0,
            max: 100,
          },
          scaleLabel: {
            display: true,
            labelString: 'Player Accuracy (%)',
          },
          gridLines: {
            color: 'rgba(255,255,255,0.1)',
            zeroLineColor: 'rgba(255,255,255,0.1)',
            zeroLineWidth: 3,
          },
        }],
      },
      responsive: true,
      maintainAspectRatio: false,
      onHover: hoverHandler,
      tooltips: {
        enabled: true,
        mode: 'index',
        intersect: true,
        displayColors: false,
        caretPadding: 3,
        callbacks: {
          label: generateTooltipLabel,
        },
      },
    }
  }

  const ctx = document.getElementById('chartCanvas').getContext('2d')
  window.myScatter = Chart.Scatter(ctx, chartConfig)
  window.myScatter.update()
}
