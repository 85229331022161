const {renderScoresaberData} = require('./render.js')

const local = () => {
  const path = `testOutput.json`
  console.log(`RUNNING LOCALLY WITH DATA FROM: ${path}`)

  renderScoresaberData(testJson)
}

module.exports.local = local

const testJson = {
  "tableRows": [
    {
      "x": 499.92493,
      "y": 94.9,
      "songName": "Villain Virus - Kobaryo",
      "yourAccuracy": 94.9,
      "predictedAccuracy": 99.0518,
      "predictedAccuracyImprovement": 4.151799999999994,
      "yourPP": 499.92,
      "predictedPP": 517.5522830318,
      "predictedPPImprovement": 17.6322830318
    },
    {
      "x": 476.43474,
      "y": 94.88,
      "songName": "SEITEN NO TERIYAKI - Kobaryo",
      "yourAccuracy": 94.88,
      "predictedAccuracy": 98.51153,
      "predictedAccuracyImprovement": 3.631529999999998,
      "yourPP": 476.43,
      "predictedPP": 491.94745513439995,
      "predictedPPImprovement": 15.517455134399938
    },
    {
      "x": 470.79464,
      "y": 95.29,
      "songName": "Flat World, Plain Asia - BLANKFIELD",
      "yourAccuracy": 95.29,
      "predictedAccuracy": 98.38181,
      "predictedAccuracyImprovement": 3.0918099999999953,
      "yourPP": 470.79,
      "predictedPP": 485.8176969624001,
      "predictedPPImprovement": 15.027696962400057
    },
    {
      "x": 454.46451,
      "y": 95.38,
      "songName": "ANOMALY - Camellia",
      "yourAccuracy": 95.38,
      "predictedAccuracy": 98.00621,
      "predictedAccuracyImprovement": 2.6262100000000004,
      "yourPP": 454.46,
      "predictedPP": 468.11207923530003,
      "predictedPPImprovement": 13.65207923530005
    },
    {
      "x": 441.49438,
      "y": 94.45,
      "songName": "A Betrayal Unforetold - Inferi",
      "yourAccuracy": 94.45,
      "predictedAccuracy": 97.7079,
      "predictedAccuracyImprovement": 3.2578999999999922,
      "yourPP": 441.49,
      "predictedPP": 454.0946296052,
      "predictedPPImprovement": 12.604629605199989
    },
    {
      "x": 444.80443,
      "y": 95.2,
      "songName": "Night Raid with a Dragon - Camellia",
      "yourAccuracy": 95.2,
      "predictedAccuracy": 97.78403,
      "predictedAccuracyImprovement": 2.5840299999999985,
      "yourPP": 444.8,
      "predictedPP": 457.66817411560004,
      "predictedPPImprovement": 12.868174115600027
    },
    {
      "x": 436.70435,
      "y": 94.22,
      "songName": "Break - The Quick Brown Fox",
      "yourAccuracy": 94.22,
      "predictedAccuracy": 97.59773,
      "predictedAccuracyImprovement": 3.3777299999999997,
      "yourPP": 435.54,
      "predictedPP": 448.92770475649996,
      "predictedPPImprovement": 13.387704756499943
    },
    {
      "x": 430.57427,
      "y": 93.41,
      "songName": "ENDYMION - fallen shepherd",
      "yourAccuracy": 93.41,
      "predictedAccuracy": 97.45674,
      "predictedAccuracyImprovement": 4.04674,
      "yourPP": 423.47,
      "predictedPP": 442.3203360856,
      "predictedPPImprovement": 18.850336085599963
    },
    {
      "x": 431.39429,
      "y": 95.65,
      "songName": "Milk Crown on Sonnetica - nameless",
      "yourAccuracy": 95.65,
      "predictedAccuracy": 97.4756,
      "predictedAccuracyImprovement": 1.8255999999999943,
      "yourPP": 431.39,
      "predictedPP": 443.2058656602,
      "predictedPPImprovement": 11.815865660200018
    },
    {
      "x": 423.14417,
      "y": 94.25,
      "songName": "tinnitus - SP-#",
      "yourAccuracy": 94.25,
      "predictedAccuracy": 97.28585,
      "predictedAccuracyImprovement": 3.0358499999999964,
      "yourPP": 423.14,
      "predictedPP": 434.32787041309996,
      "predictedPPImprovement": 11.187870413099972
    },
    {
      "x": 417.34415,
      "y": 95.65,
      "songName": "RAVE-O-LUTION 456 - t+pazolite",
      "yourAccuracy": 95.65,
      "predictedAccuracy": 97.15245,
      "predictedAccuracyImprovement": 1.502449999999996,
      "yourPP": 417.34,
      "predictedPP": 428.094935304,
      "predictedPPImprovement": 10.754935304000014
    },
    {
      "x": 415.67414,
      "y": 95.64,
      "songName": "Happppy song - SOOOO",
      "yourAccuracy": 95.64,
      "predictedAccuracy": 97.11404,
      "predictedAccuracyImprovement": 1.4740400000000022,
      "yourPP": 415.67,
      "predictedPP": 426.30292775980007,
      "predictedPPImprovement": 10.632927759800054
    },
    {
      "x": 407.93406,
      "y": 94.23,
      "songName": "iLLness LiLin - Kaneko Chiharu",
      "yourAccuracy": 94.23,
      "predictedAccuracy": 96.93601,
      "predictedAccuracyImprovement": 2.706009999999992,
      "yourPP": 407.93,
      "predictedPP": 418.0018726008,
      "predictedPPImprovement": 10.07187260080002
    },
    {
      "x": 397.61397,
      "y": 94.92,
      "songName": "NUCLEAR-STAR - Camellia",
      "yourAccuracy": 94.92,
      "predictedAccuracy": 96.69865,
      "predictedAccuracyImprovement": 1.778649999999999,
      "yourPP": 397.61,
      "predictedPP": 406.9539221553,
      "predictedPPImprovement": 9.343922155299992
    },
    {
      "x": 396.39391,
      "y": 95.58,
      "songName": "Renegade Fruits - t+pazolite",
      "yourAccuracy": 95.58,
      "predictedAccuracy": 96.67059,
      "predictedAccuracyImprovement": 1.090590000000006,
      "yourPP": 396.39,
      "predictedPP": 405.6497077985,
      "predictedPPImprovement": 9.259707798500017
    },
    {
      "x": 394.22393,
      "y": 95.99,
      "songName": "Recollection - Kobaryo",
      "yourAccuracy": 95.99,
      "predictedAccuracy": 96.62068,
      "predictedAccuracyImprovement": 0.6306799999999981,
      "yourPP": 394.22,
      "predictedPP": 403.33050278299993,
      "predictedPPImprovement": 9.110502782999902
    },
    {
      "x": 392.52391,
      "y": 95.91,
      "songName": "Chrome VOX (Uncut Edition) - t+pazolite",
      "yourAccuracy": 95.91,
      "predictedAccuracy": 96.58158,
      "predictedAccuracyImprovement": 0.6715800000000058,
      "yourPP": 392.52,
      "predictedPP": 401.5166327781,
      "predictedPPImprovement": 8.996632778100036
    },
    {
      "x": 387.66384,
      "y": 95.4,
      "songName": "G1ll35 d3 R415 - Team Grimoire",
      "yourAccuracy": 95.4,
      "predictedAccuracy": 96.4698,
      "predictedAccuracyImprovement": 1.0698000000000008,
      "yourPP": 387.66,
      "predictedPP": 396.32812682400004,
      "predictedPPImprovement": 8.668126824000012
    },
    {
      "x": 383.90382,
      "y": 94.77,
      "songName": "Louder Than Steel - 「Ryu-5150」",
      "yourAccuracy": 94.77,
      "predictedAccuracy": 96.38332,
      "predictedAccuracyImprovement": 1.6133200000000016,
      "yourPP": 383.9,
      "predictedPP": 392.31899173439996,
      "predictedPPImprovement": 8.418991734399981
    },
    {
      "x": 380.16377,
      "y": 95.56,
      "songName": "Daisuki, Evolution - Minazuki Airi with Atsushi",
      "yourAccuracy": 95.56,
      "predictedAccuracy": 96.2973,
      "predictedAccuracyImprovement": 0.7373000000000047,
      "yourPP": 380.16,
      "predictedPP": 388.33348941730003,
      "predictedPPImprovement": 8.173489417300004
    },
    {
      "x": 376.85376,
      "y": 94.91,
      "songName": "Hype feat. Such - PSYQUI",
      "yourAccuracy": 94.91,
      "predictedAccuracy": 96.22117,
      "predictedAccuracyImprovement": 1.3111700000000042,
      "yourPP": 376.38,
      "predictedPP": 384.8091428736,
      "predictedPPImprovement": 8.429142873600028
    },
    {
      "x": 381.0238,
      "y": 96.43,
      "songName": "Arche - Camellia",
      "yourAccuracy": 96.43,
      "predictedAccuracy": 96.31708,
      "predictedAccuracyImprovement": -0.11292000000000257,
      "yourPP": 381.02,
      "predictedPP": 389.250103842,
      "predictedPPImprovement": 8.230103842000005
    },
    {
      "x": 379.54375,
      "y": 95.85,
      "songName": "GHOST - Camellia",
      "yourAccuracy": 95.85,
      "predictedAccuracy": 96.28304,
      "predictedAccuracyImprovement": 0.4330400000000054,
      "yourPP": 379.54,
      "predictedPP": 387.673577125,
      "predictedPPImprovement": 8.133577124999988
    },
    {
      "x": 370.73369,
      "y": 94.91,
      "songName": "Enigma II - Toromaru",
      "yourAccuracy": 94.91,
      "predictedAccuracy": 96.0804,
      "predictedAccuracyImprovement": 1.1704000000000008,
      "yourPP": 370.73,
      "predictedPP": 378.296657276,
      "predictedPPImprovement": 7.566657276000001
    },
    {
      "x": 374.83374,
      "y": 96,
      "songName": "Night sky - USAO",
      "yourAccuracy": 96,
      "predictedAccuracy": 96.17471,
      "predictedAccuracyImprovement": 0.17471000000000458,
      "yourPP": 374.83,
      "predictedPP": 382.65652015379993,
      "predictedPPImprovement": 7.826520153799947
    },
    {
      "x": 370.20369,
      "y": 95.15,
      "songName": "Acid burst - Memme",
      "yourAccuracy": 95.15,
      "predictedAccuracy": 96.06821,
      "predictedAccuracyImprovement": 0.9182099999999878,
      "yourPP": 370.2,
      "predictedPP": 377.7336330546,
      "predictedPPImprovement": 7.5336330545999886
    },
    {
      "x": 372.35372,
      "y": 95.83,
      "songName": "werewolf howls. - Camellia",
      "yourAccuracy": 95.83,
      "predictedAccuracy": 96.11767,
      "predictedAccuracyImprovement": 0.28767000000000564,
      "yourPP": 372.35,
      "predictedPP": 380.02048309480006,
      "predictedPPImprovement": 7.6704830948000335
    },
    {
      "x": 366.19364,
      "y": 94.94,
      "songName": "Break - The Quick Brown Fox",
      "yourAccuracy": 94.94,
      "predictedAccuracy": 95.97598,
      "predictedAccuracyImprovement": 1.0359800000000092,
      "yourPP": 366.19,
      "predictedPP": 373.41131664439996,
      "predictedPPImprovement": 7.221316644399963
    },
    {
      "x": 366.84366,
      "y": 95.61,
      "songName": "Walk This Way! - Kano",
      "yourAccuracy": 95.61,
      "predictedAccuracy": 95.99093,
      "predictedAccuracyImprovement": 0.38093000000000643,
      "yourPP": 366.84,
      "predictedPP": 374.1401803974,
      "predictedPPImprovement": 7.300180397400027
    },
    {
      "x": 362.21361,
      "y": 95.05,
      "songName": "Renegade Fruits - t+pazolite",
      "yourAccuracy": 95.05,
      "predictedAccuracy": 95.88444,
      "predictedAccuracyImprovement": 0.8344400000000007,
      "yourPP": 361.83,
      "predictedPP": 368.9544052821,
      "predictedPPImprovement": 7.124405282100042
    },
    {
      "x": 363.73362,
      "y": 95.58,
      "songName": "B L A C K - R A Y - Camellia",
      "yourAccuracy": 95.58,
      "predictedAccuracy": 95.9194,
      "predictedAccuracyImprovement": 0.3393999999999977,
      "yourPP": 362.9,
      "predictedPP": 370.6554707886,
      "predictedPPImprovement": 7.755470788600007
    },
    {
      "x": 364.3636,
      "y": 95.93,
      "songName": "osu!memories - SakiZ",
      "yourAccuracy": 95.93,
      "predictedAccuracy": 95.93389,
      "predictedAccuracyImprovement": 0.0038899999999983947,
      "yourPP": 364.36,
      "predictedPP": 371.363024756,
      "predictedPPImprovement": 7.003024756000002
    },
    {
      "x": 361.4336,
      "y": 95.61,
      "songName": "Flow - Ace Aura",
      "yourAccuracy": 95.61,
      "predictedAccuracy": 95.8665,
      "predictedAccuracyImprovement": 0.2565000000000026,
      "yourPP": 360.8,
      "predictedPP": 368.08397824,
      "predictedPPImprovement": 7.28397824000001
    },
    {
      "x": 356.95354,
      "y": 96.24,
      "songName": "Distortion - BABYMETAL",
      "yourAccuracy": 96.24,
      "predictedAccuracy": 95.76346,
      "predictedAccuracyImprovement": -0.47653999999999996,
      "yourPP": 356.95,
      "predictedPP": 363.0788627464,
      "predictedPPImprovement": 6.128862746399989
    },
    {
      "x": 354.38353,
      "y": 96.6,
      "songName": "Heeartbeeat Oveerheeat!!!! - Camellia feat. Nanahira",
      "yourAccuracy": 96.6,
      "predictedAccuracy": 95.70435,
      "predictedAccuracyImprovement": -0.8956499999999892,
      "yourPP": 354.38,
      "predictedPP": 360.2131390685,
      "predictedPPImprovement": 5.8331390685000315
    },
    {
      "x": 350.24349,
      "y": 96.01,
      "songName": "Tool-Assisted Speedcore - Kobaryo",
      "yourAccuracy": 96.01,
      "predictedAccuracy": 95.60913,
      "predictedAccuracyImprovement": -0.40087000000001183,
      "yourPP": 350.24,
      "predictedPP": 355.6057178319,
      "predictedPPImprovement": 5.365717831899985
    },
    {
      "x": 360.42359,
      "y": 95.15,
      "songName": "Overkill - RIOT",
      "yourAccuracy": 95.15,
      "predictedAccuracy": 95.84327,
      "predictedAccuracyImprovement": 0.6932699999999983,
      "yourPP": 360.42,
      "predictedPP": 366.95446545079994,
      "predictedPPImprovement": 6.534465450799928
    },
    {
      "x": 348.89348,
      "y": 95.92,
      "songName": "Bangin' Burst  - Camellia",
      "yourAccuracy": 95.92,
      "predictedAccuracy": 95.57808,
      "predictedAccuracyImprovement": -0.3419200000000018,
      "yourPP": 348.89,
      "predictedPP": 354.1059485912,
      "predictedPPImprovement": 5.215948591200004
    },
    {
      "x": 344.73342,
      "y": 94.94,
      "songName": "Brain Power (SDVX Mix) - NOMA",
      "yourAccuracy": 94.94,
      "predictedAccuracy": 95.4824,
      "predictedAccuracyImprovement": 0.5424000000000007,
      "yourPP": 344.73,
      "predictedPP": 349.4872938618,
      "predictedPPImprovement": 4.757293861800008
    },
    {
      "x": 343.98343,
      "y": 97.12,
      "songName": "Flat World, Plain Asia - BLANKFIELD",
      "yourAccuracy": 97.12,
      "predictedAccuracy": 95.46515,
      "predictedAccuracyImprovement": -1.6548500000000104,
      "yourPP": 343.98,
      "predictedPP": 348.6547249794,
      "predictedPPImprovement": 4.674724979399969
    },
    {
      "x": 337.64337,
      "y": 95.55,
      "songName": "NANI THE FUCK!! - Camellia x Nanahira",
      "yourAccuracy": 95.55,
      "predictedAccuracy": 95.31933,
      "predictedAccuracyImprovement": -0.23067000000000348,
      "yourPP": 337.64,
      "predictedPP": 341.6376910671,
      "predictedPPImprovement": 3.997691067099993
    },
    {
      "x": 339.16336,
      "y": 95.99,
      "songName": "SEITEN NO TERIYAKI - Kobaryo",
      "yourAccuracy": 95.99,
      "predictedAccuracy": 95.35429,
      "predictedAccuracyImprovement": -0.6357099999999889,
      "yourPP": 339.16,
      "predictedPP": 343.31811116000006,
      "predictedPPImprovement": 4.158111160000033
    },
    {
      "x": 337.11336,
      "y": 95.34,
      "songName": "Blue Zenith  - xi",
      "yourAccuracy": 95.34,
      "predictedAccuracy": 95.30714,
      "predictedAccuracyImprovement": -0.032859999999999445,
      "yourPP": 336.17,
      "predictedPP": 341.0542151784,
      "predictedPPImprovement": 4.884215178399984
    },
    {
      "x": 338.09337,
      "y": 95.88,
      "songName": "PUMP - YUC'e",
      "yourAccuracy": 95.88,
      "predictedAccuracy": 95.32968,
      "predictedAccuracyImprovement": -0.5503199999999993,
      "yourPP": 338.09,
      "predictedPP": 342.1369667052,
      "predictedPPImprovement": 4.04696670520002
    },
    {
      "x": 337.67337,
      "y": 96.03,
      "songName": "volcanic - DETRO",
      "yourAccuracy": 96.03,
      "predictedAccuracy": 95.32002,
      "predictedAccuracyImprovement": -0.7099800000000016,
      "yourPP": 337.67,
      "predictedPP": 341.6714227008,
      "predictedPPImprovement": 4.001422700799992
    },
    {
      "x": 336.42334,
      "y": 95.96,
      "songName": "sink to the deep sea world - Chroma",
      "yourAccuracy": 95.96,
      "predictedAccuracy": 95.29127,
      "predictedAccuracyImprovement": -0.6687299999999965,
      "yourPP": 336.42,
      "predictedPP": 340.29220841,
      "predictedPPImprovement": 3.8722084099999847
    },
    {
      "x": 336.14334,
      "y": 96.14,
      "songName": "Yugure - Kobaryo",
      "yourAccuracy": 96.14,
      "predictedAccuracy": 95.28483,
      "predictedAccuracyImprovement": -0.8551700000000011,
      "yourPP": 336.14,
      "predictedPP": 339.9820969428,
      "predictedPPImprovement": 3.8420969428000262
    },
    {
      "x": 334.50333,
      "y": 95.83,
      "songName": "YELL! (feat. Moimoi) - DJ Genki & Camellia",
      "yourAccuracy": 95.83,
      "predictedAccuracy": 95.24711,
      "predictedAccuracyImprovement": -0.5828899999999919,
      "yourPP": 333.75,
      "predictedPP": 338.1728315301,
      "predictedPPImprovement": 4.422831530099984
    },
    {
      "x": 334.12333,
      "y": 96.12,
      "songName": "Night Raid with a Dragon - Camellia",
      "yourAccuracy": 96.12,
      "predictedAccuracy": 95.23837,
      "predictedAccuracyImprovement": -0.8816300000000012,
      "yourPP": 334.12,
      "predictedPP": 337.75190936380005,
      "predictedPPImprovement": 3.631909363800048
    },
    {
      "x": 331.4033,
      "y": 95.88,
      "songName": "iLLness LiLin - Kaneko Chiharu",
      "yourAccuracy": 95.88,
      "predictedAccuracy": 95.17581,
      "predictedAccuracyImprovement": -0.704189999999997,
      "yourPP": 331.4,
      "predictedPP": 334.753787363,
      "predictedPPImprovement": 3.3537873630000377
    },
    {
      "x": 332.0133,
      "y": 95.8,
      "songName": "Freedom Dive - v2",
      "yourAccuracy": 95.8,
      "predictedAccuracy": 95.18984,
      "predictedAccuracyImprovement": -0.6101599999999934,
      "yourPP": 330.72,
      "predictedPP": 335.426396724,
      "predictedPPImprovement": 4.706396724000001
    },
    {
      "x": 329.70329,
      "y": 95.67,
      "songName": "Monster - Reol",
      "yourAccuracy": 95.67,
      "predictedAccuracy": 95.13671,
      "predictedAccuracyImprovement": -0.533290000000008,
      "yourPP": 329.35,
      "predictedPP": 332.8816297156,
      "predictedPPImprovement": 3.5316297155999905
    },
    {
      "x": 326.57323,
      "y": 95.03,
      "songName": "Labyrinth - Lapix",
      "yourAccuracy": 95.03,
      "predictedAccuracy": 95.06471,
      "predictedAccuracyImprovement": 0.034710000000004015,
      "yourPP": 326.57,
      "predictedPP": 329.44054295940003,
      "predictedPPImprovement": 2.8705429594000407
    },
    {
      "x": 329.70328,
      "y": 95.68,
      "songName": "Legend of Millennium - Hazuki",
      "yourAccuracy": 95.68,
      "predictedAccuracy": 95.13671,
      "predictedAccuracyImprovement": -0.5432900000000132,
      "yourPP": 328.5,
      "predictedPP": 332.8816196192,
      "predictedPPImprovement": 4.381619619200023
    },
    {
      "x": 330.2833,
      "y": 96.31,
      "songName": "Routine - Silent Siren",
      "yourAccuracy": 96.31,
      "predictedAccuracy": 95.15005,
      "predictedAccuracyImprovement": -1.1599500000000091,
      "yourPP": 330.06,
      "predictedPP": 333.52007634,
      "predictedPPImprovement": 3.4600763400000005
    },
    {
      "x": 323.44321,
      "y": 95.98,
      "songName": "EmbryO - Kabocha",
      "yourAccuracy": 95.98,
      "predictedAccuracy": 94.99272,
      "predictedAccuracyImprovement": -0.9872799999999984,
      "yourPP": 323.44,
      "predictedPP": 326.00164579110003,
      "predictedPPImprovement": 2.561645791100034
    },
    {
      "x": 323.78322,
      "y": 95.66,
      "songName": "Ascension to Heaven - xi",
      "yourAccuracy": 95.66,
      "predictedAccuracy": 95.00054,
      "predictedAccuracyImprovement": -0.6594599999999957,
      "yourPP": 322.19,
      "predictedPP": 326.3767235922,
      "predictedPPImprovement": 4.186723592199996
    },
    {
      "x": 321.43321,
      "y": 95.63,
      "songName": "RED ZONE - Tatsh & NAOKI",
      "yourAccuracy": 95.63,
      "predictedAccuracy": 94.94649,
      "predictedAccuracyImprovement": -0.6835099999999983,
      "yourPP": 321.35,
      "predictedPP": 323.7989584256,
      "predictedPPImprovement": 2.448958425599983
    },
    {
      "x": 319.95319,
      "y": 95.55,
      "songName": "Bluenation - yaseta",
      "yourAccuracy": 95.55,
      "predictedAccuracy": 94.91245,
      "predictedAccuracyImprovement": -0.6375499999999903,
      "yourPP": 319.95,
      "predictedPP": 322.17686467050004,
      "predictedPPImprovement": 2.226864670500049
    },
    {
      "x": 317.32316,
      "y": 95.98,
      "songName": "Battle Sirens (RIOT Remix) - Knife Party & Tom Morello",
      "yourAccuracy": 95.98,
      "predictedAccuracy": 94.85196,
      "predictedAccuracyImprovement": -1.1280399999999986,
      "yourPP": 316.49,
      "predictedPP": 319.29691005519993,
      "predictedPPImprovement": 2.8069100551999213
    },
    {
      "x": 314.97314,
      "y": 95.67,
      "songName": "Ange du Blanc Pur - ke-ji feat. Nanahira",
      "yourAccuracy": 95.67,
      "predictedAccuracy": 94.79791,
      "predictedAccuracyImprovement": -0.87209,
      "yourPP": 314.97,
      "predictedPP": 316.7275403898,
      "predictedPPImprovement": 1.757540389799999
    },
    {
      "x": 312.33311,
      "y": 94.75,
      "songName": "Setsuna Imitation (feat. Yurica, Hanatan)  - Yairi",
      "yourAccuracy": 94.75,
      "predictedAccuracy": 94.73719,
      "predictedAccuracyImprovement": -0.012810000000001764,
      "yourPP": 311.45,
      "predictedPP": 313.8479255835,
      "predictedPPImprovement": 2.3979255835000117
    },
    {
      "x": 317.43317,
      "y": 96.96,
      "songName": "RAVE-O-LUTION 456 - t+pazolite",
      "yourAccuracy": 96.96,
      "predictedAccuracy": 94.85449,
      "predictedAccuracyImprovement": -2.1055099999999953,
      "yourPP": 317.03,
      "predictedPP": 319.41712731250004,
      "predictedPPImprovement": 2.38712731250007
    },
    {
      "x": 310.8931,
      "y": 95.67,
      "songName": "TIERRA - Dj Genki",
      "yourAccuracy": 95.67,
      "predictedAccuracy": 94.70407,
      "predictedAccuracyImprovement": -0.9659300000000002,
      "yourPP": 310.3,
      "predictedPP": 312.27657429500005,
      "predictedPPImprovement": 1.9765742950000345
    },
    {
      "x": 311.97311,
      "y": 96.41,
      "songName": "M1917 - a crowd of rebellion",
      "yourAccuracy": 96.41,
      "predictedAccuracy": 94.72891,
      "predictedAccuracyImprovement": -1.6810899999999975,
      "yourPP": 311.97,
      "predictedPP": 313.45498227250005,
      "predictedPPImprovement": 1.4849822725000195
    },
    {
      "x": 305.09303,
      "y": 93.99,
      "songName": "HONESTY - GYZE feat. Ettore Rigotti",
      "yourAccuracy": 93.99,
      "predictedAccuracy": 94.57067,
      "predictedAccuracyImprovement": 0.580670000000012,
      "yourPP": 302.6,
      "predictedPP": 305.9625451355,
      "predictedPPImprovement": 3.3625451354999996
    },
    {
      "x": 309.69307,
      "y": 96.22,
      "songName": "Galactic Symphony - Mandragora",
      "yourAccuracy": 96.22,
      "predictedAccuracy": 94.67647,
      "predictedAccuracyImprovement": -1.543530000000004,
      "yourPP": 309.69,
      "predictedPP": 310.96900544839997,
      "predictedPPImprovement": 1.279005448399971
    },
    {
      "x": 306.57306,
      "y": 96.47,
      "songName": "Avalanche - Memme",
      "yourAccuracy": 96.47,
      "predictedAccuracy": 94.60471,
      "predictedAccuracyImprovement": -1.8652900000000017,
      "yourPP": 306.57,
      "predictedPP": 307.57248817560003,
      "predictedPPImprovement": 1.0024881756000354
    },
    {
      "x": 305.76305,
      "y": 96.72,
      "songName": "Distortion - BABYMETAL",
      "yourAccuracy": 96.72,
      "predictedAccuracy": 94.58608,
      "predictedAccuracyImprovement": -2.1339200000000034,
      "yourPP": 305.17,
      "predictedPP": 306.68951204150005,
      "predictedPPImprovement": 1.519512041500036
    },
    {
      "x": 302.73302,
      "y": 95.83,
      "songName": "Blue Zenith - xi",
      "yourAccuracy": 95.83,
      "predictedAccuracy": 94.51639,
      "predictedAccuracyImprovement": -1.313609999999997,
      "yourPP": 302.33,
      "predictedPP": 303.399032644,
      "predictedPPImprovement": 1.0690326440000035
    },
    {
      "x": 302.95302,
      "y": 95.65,
      "songName": "Teo (ft. Hatsune Miku) - Omoi",
      "yourAccuracy": 95.65,
      "predictedAccuracy": 94.52145,
      "predictedAccuracyImprovement": -1.1285500000000042,
      "yourPP": 301.45,
      "predictedPP": 303.63769382519996,
      "predictedPPImprovement": 2.187693825199972
    },
    {
      "x": 301.90301,
      "y": 95.92,
      "songName": "Saint's Beat - Zeami",
      "yourAccuracy": 95.92,
      "predictedAccuracy": 94.4973,
      "predictedAccuracyImprovement": -1.422700000000006,
      "yourPP": 301.8,
      "predictedPP": 302.4977589297,
      "predictedPPImprovement": 0.6977589297000009
    },
    {
      "x": 297.48295,
      "y": 94.3,
      "songName": "Tool-Assisted Speedcore - Kobaryo",
      "yourAccuracy": 94.3,
      "predictedAccuracy": 94.39564,
      "predictedAccuracyImprovement": 0.09564000000000306,
      "yourPP": 295.87,
      "predictedPP": 297.7060622125,
      "predictedPPImprovement": 1.8360622124999963
    },
    {
      "x": 299.78299,
      "y": 96.45,
      "songName": "Zetsubou Plantation - Yousei Teikoku",
      "yourAccuracy": 96.45,
      "predictedAccuracy": 94.44854,
      "predictedAccuracyImprovement": -2.0014600000000087,
      "yourPP": 299.4,
      "predictedPP": 300.1966905262,
      "predictedPPImprovement": 0.7966905261999955
    },
    {
      "x": 293.37291,
      "y": 94.44,
      "songName": "TWISTSTEP - Pa's Lam System",
      "yourAccuracy": 94.44,
      "predictedAccuracy": 94.30111,
      "predictedAccuracyImprovement": -0.1388900000000035,
      "yourPP": 290.59,
      "predictedPP": 293.25849456509997,
      "predictedPPImprovement": 2.6684945650999907
    },
    {
      "x": 292.7229,
      "y": 95.91,
      "songName": "quaver - dj TAKA",
      "yourAccuracy": 95.91,
      "predictedAccuracy": 94.28616,
      "predictedAccuracyImprovement": -1.6238400000000013,
      "yourPP": 292.72,
      "predictedPP": 292.55604794699997,
      "predictedPPImprovement": -0.16395205300005955
    },
    {
      "x": 291.69291,
      "y": 96.41,
      "songName": "Hentacito - Luis Fonsi ft. Daddy Yankee/S3RL/DaymanOurSavior",
      "yourAccuracy": 96.41,
      "predictedAccuracy": 94.26247,
      "predictedAccuracyImprovement": -2.1475300000000033,
      "yourPP": 291.69,
      "predictedPP": 291.44497102649996,
      "predictedPPImprovement": -0.24502897350004105
    },
    {
      "x": 288.70287,
      "y": 96.1,
      "songName": "RELOADED - EGOIST",
      "yourAccuracy": 96.1,
      "predictedAccuracy": 94.1937,
      "predictedAccuracyImprovement": -1.9062999999999874,
      "yourPP": 288.7,
      "predictedPP": 288.2178491784,
      "predictedPPImprovement": -0.482150821599987
    },
    {
      "x": 286.82284,
      "y": 94.26,
      "songName": "Magnetism - M2U",
      "yourAccuracy": 94.26,
      "predictedAccuracy": 94.15046,
      "predictedAccuracyImprovement": -0.10954000000000974,
      "yourPP": 281.73,
      "predictedPP": 286.19469798039995,
      "predictedPPImprovement": 4.464697980399933
    },
    {
      "x": 283.22283,
      "y": 95.33,
      "songName": "Great Distance - ryo (supercell)",
      "yourAccuracy": 95.33,
      "predictedAccuracy": 94.06766,
      "predictedAccuracyImprovement": -1.2623399999999947,
      "yourPP": 282.46,
      "predictedPP": 282.3193491723,
      "predictedPPImprovement": -0.14065082769997161
    },
    {
      "x": 284.73283,
      "y": 96.32,
      "songName": "g a r d e n (Short Ver.) - DM DOKURO",
      "yourAccuracy": 96.32,
      "predictedAccuracy": 94.10239,
      "predictedAccuracyImprovement": -2.2176099999999934,
      "yourPP": 284.73,
      "predictedPP": 283.9441200609,
      "predictedPPImprovement": -0.7858799391000275
    },
    {
      "x": 281.47279,
      "y": 95.17,
      "songName": "Akasha - xi",
      "yourAccuracy": 95.17,
      "predictedAccuracy": 94.0274,
      "predictedAccuracyImprovement": -1.1426000000000016,
      "yourPP": 281.47,
      "predictedPP": 280.4397848607,
      "predictedPPImprovement": -1.0302151393000258
    },
    {
      "x": 281.3628,
      "y": 95.06,
      "songName": "Quo Vadis - M2U",
      "yourAccuracy": 95.06,
      "predictedAccuracy": 94.02487,
      "predictedAccuracyImprovement": -1.0351299999999952,
      "yourPP": 280.41,
      "predictedPP": 280.32175764,
      "predictedPPImprovement": -0.08824236000003793
    },
    {
      "x": 277.23277,
      "y": 94.27,
      "songName": "Der Wald (kors k Remix) - Erehamonika remixed by kors k",
      "yourAccuracy": 94.27,
      "predictedAccuracy": 93.92988,
      "predictedAccuracyImprovement": -0.34011999999999887,
      "yourPP": 277.17,
      "predictedPP": 275.8909633932,
      "predictedPPImprovement": -1.2790366067999912
    },
    {
      "x": 278.91277,
      "y": 95.41,
      "songName": "Mare Maris - M2U",
      "yourAccuracy": 95.41,
      "predictedAccuracy": 93.96852,
      "predictedAccuracyImprovement": -1.4414799999999985,
      "yourPP": 278.91,
      "predictedPP": 277.6911320674,
      "predictedPPImprovement": -1.2188679326000056
    },
    {
      "x": 275.91274,
      "y": 94.8,
      "songName": "Last Proof - ZAQ",
      "yourAccuracy": 94.8,
      "predictedAccuracy": 93.89952,
      "predictedAccuracyImprovement": -0.9004800000000017,
      "yourPP": 274.76,
      "predictedPP": 274.4752346246,
      "predictedPPImprovement": -0.28476537540001345
    },
    {
      "x": 269.53269,
      "y": 92.78,
      "songName": "Cycle Hit - KASAI HARCORES",
      "yourAccuracy": 92.78,
      "predictedAccuracy": 93.75278,
      "predictedAccuracyImprovement": 0.9727800000000002,
      "yourPP": 267.19,
      "predictedPP": 267.65404715069997,
      "predictedPPImprovement": 0.4640471506999688
    },
    {
      "x": 278.65278,
      "y": 95.77,
      "songName": "ALTONA  - SOUND HOLIC Vs. SWING HOLIC",
      "yourAccuracy": 95.77,
      "predictedAccuracy": 93.96254,
      "predictedAccuracyImprovement": -1.8074599999999919,
      "yourPP": 277.72,
      "predictedPP": 277.412775129,
      "predictedPPImprovement": -0.3072248710000167
    },
    {
      "x": 277.67276,
      "y": 95.91,
      "songName": "Legend of Millennium - Hazuki",
      "yourAccuracy": 95.91,
      "predictedAccuracy": 93.94,
      "predictedAccuracyImprovement": -1.9699999999999989,
      "yourPP": 277.67,
      "predictedPP": 276.3621445728,
      "predictedPPImprovement": -1.3078554272000247
    },
    {
      "x": 276.45276,
      "y": 96.6,
      "songName": "V for EXTREME - dj TAKA",
      "yourAccuracy": 96.6,
      "predictedAccuracy": 93.91194,
      "predictedAccuracyImprovement": -2.688059999999993,
      "yourPP": 276.45,
      "predictedPP": 275.0539090344,
      "predictedPPImprovement": -1.3960909655999671
    },
    {
      "x": 272.93272,
      "y": 95.17,
      "songName": "FIRST - AKI AKANE",
      "yourAccuracy": 95.17,
      "predictedAccuracy": 93.83098,
      "predictedAccuracyImprovement": -1.339020000000005,
      "yourPP": 272.71,
      "predictedPP": 271.28693569840004,
      "predictedPPImprovement": -1.4230643015999362
    },
    {
      "x": 272.85271,
      "y": 95.19,
      "songName": "flying in the flow of deep-sea (Short Ver.) - Camellia",
      "yourAccuracy": 95.19,
      "predictedAccuracy": 93.82914,
      "predictedAccuracyImprovement": -1.3608600000000024,
      "yourPP": 271.65,
      "predictedPP": 271.2019511045,
      "predictedPPImprovement": -0.44804889549999416
    },
    {
      "x": 275.21274,
      "y": 95.86,
      "songName": "Phantasia - Yunosuke",
      "yourAccuracy": 95.86,
      "predictedAccuracy": 93.88342,
      "predictedAccuracyImprovement": -1.9765799999999984,
      "yourPP": 273.82,
      "predictedPP": 273.726591204,
      "predictedPPImprovement": -0.09340879600000562
    },
    {
      "x": 274.52274,
      "y": 96.19,
      "songName": "Two-Faced Lovers feat. Miku - by Wowaka",
      "yourAccuracy": 96.19,
      "predictedAccuracy": 93.86755,
      "predictedAccuracyImprovement": -2.3224500000000035,
      "yourPP": 274.11,
      "predictedPP": 272.9881578834,
      "predictedPPImprovement": -1.1218421166000212
    },
    {
      "x": 272.31271,
      "y": 95.69,
      "songName": "Solace of Oblivion - Helblinde",
      "yourAccuracy": 95.69,
      "predictedAccuracy": 93.81672,
      "predictedAccuracyImprovement": -1.873279999999994,
      "yourPP": 271.79,
      "predictedPP": 270.624371198,
      "predictedPPImprovement": -1.1656288020000147
    },
    {
      "x": 269.41268,
      "y": 95.05,
      "songName": "CANDYYYLAND (Pa's Lam System Remix) - tofubeats",
      "yourAccuracy": 95.05,
      "predictedAccuracy": 93.75002,
      "predictedAccuracyImprovement": -1.2999799999999908,
      "yourPP": 268.44,
      "predictedPP": 267.52679124,
      "predictedPPImprovement": -0.913208759999975
    },
    {
      "x": 261.14259,
      "y": 94.67,
      "songName": "Halcyon - xi",
      "yourAccuracy": 94.67,
      "predictedAccuracy": 93.55981,
      "predictedAccuracyImprovement": -1.110190000000003,
      "yourPP": 259.27,
      "predictedPP": 258.7191867648,
      "predictedPPImprovement": -0.5508132351999961
    },
    {
      "x": 263.40263,
      "y": 95.84,
      "songName": "HIBANA feat. Hatsune Miku - DECO*27",
      "yourAccuracy": 95.84,
      "predictedAccuracy": 93.61179,
      "predictedAccuracyImprovement": -2.2282100000000042,
      "yourPP": 262.61,
      "predictedPP": 261.1215632242,
      "predictedPPImprovement": -1.4884367758000394
    },
    {
      "x": 263.18262,
      "y": 96,
      "songName": "Black Emperor - Chart by Mystikmol",
      "yourAccuracy": 96,
      "predictedAccuracy": 93.60673,
      "predictedAccuracyImprovement": -2.393270000000001,
      "yourPP": 262.66,
      "predictedPP": 260.8876675536,
      "predictedPPImprovement": -1.7723324464000143
    },
    {
      "x": 261.21261,
      "y": 95.81,
      "songName": "Fallen (Short Ver.) - EGOIST",
      "yourAccuracy": 95.81,
      "predictedAccuracy": 93.56142,
      "predictedAccuracyImprovement": -2.248580000000004,
      "yourPP": 261.12,
      "predictedPP": 258.7937812314,
      "predictedPPImprovement": -2.326218768600029
    },
    {
      "x": 260.29259,
      "y": 94.76,
      "songName": "Sidetracked Day (Short Ver.) - VINXIS",
      "yourAccuracy": 94.76,
      "predictedAccuracy": 93.54026,
      "predictedAccuracyImprovement": -1.2197400000000016,
      "yourPP": 257.67,
      "predictedPP": 257.8146045432,
      "predictedPPImprovement": 0.14460454319998917
    },
    {
      "x": 261.04261,
      "y": 95.93,
      "songName": "MIRROR - THE ORAL CIGARETTES",
      "yourAccuracy": 95.93,
      "predictedAccuracy": 93.55751,
      "predictedAccuracyImprovement": -2.3724900000000133,
      "yourPP": 260.41,
      "predictedPP": 258.6123033009,
      "predictedPPImprovement": -1.7976966991000154
    },
    {
      "x": 258.28256,
      "y": 95.86,
      "songName": "Wish upon Twin Stars - xi",
      "yourAccuracy": 95.86,
      "predictedAccuracy": 93.49403,
      "predictedAccuracyImprovement": -2.3659700000000043,
      "yourPP": 258.28,
      "predictedPP": 255.67648896959997,
      "predictedPPImprovement": -2.6035110304
    },
    {
      "x": 258.88258,
      "y": 97,
      "songName": "DeltaMAX - DM Ashura",
      "yourAccuracy": 97,
      "predictedAccuracy": 93.50783,
      "predictedAccuracyImprovement": -3.4921700000000016,
      "yourPP": 258.39,
      "predictedPP": 256.31705363220004,
      "predictedPPImprovement": -2.0729463677999433
    },
    {
      "x": 254.81254,
      "y": 96.89,
      "songName": "Senpai Notice Me - Camellia ft. Nanahira",
      "yourAccuracy": 96.89,
      "predictedAccuracy": 93.41422,
      "predictedAccuracyImprovement": -3.4757800000000003,
      "yourPP": 254.81,
      "predictedPP": 251.9229657964,
      "predictedPPImprovement": -2.8870342035999954
    },
    {
      "x": 250.3525,
      "y": 95.63,
      "songName": "Ether Strike - Akira Complex",
      "yourAccuracy": 95.63,
      "predictedAccuracy": 93.31164,
      "predictedAccuracyImprovement": -2.3183599999999984,
      "yourPP": 250.35,
      "predictedPP": 247.11043512499998,
      "predictedPPImprovement": -3.2395648750000134
    },
    {
      "x": 250.79249,
      "y": 95.77,
      "songName": "Inferno - 9mm Parabellum Bullet",
      "yourAccuracy": 95.77,
      "predictedAccuracy": 93.32176,
      "predictedAccuracyImprovement": -2.4482399999999984,
      "yourPP": 250.79,
      "predictedPP": 247.5848540529,
      "predictedPPImprovement": -3.205145947099993
    },
    {
      "x": 252.17252,
      "y": 96.5,
      "songName": "You're Everything - P*Light vs Yuyoyuppe",
      "yourAccuracy": 96.5,
      "predictedAccuracy": 93.3535,
      "predictedAccuracyImprovement": -3.146500000000003,
      "yourPP": 252.01,
      "predictedPP": 249.0733197292,
      "predictedPPImprovement": -2.9366802707999966
    },
    {
      "x": 250.82249,
      "y": 95.87,
      "songName": "Future Candy - YUC'e",
      "yourAccuracy": 95.87,
      "predictedAccuracy": 93.32245,
      "predictedAccuracyImprovement": -2.547550000000001,
      "yourPP": 250.82,
      "predictedPP": 247.61697857779998,
      "predictedPPImprovement": -3.2030214222000097
    },
    {
      "x": 249.80249,
      "y": 96.46,
      "songName": "Ievan Polkka - Nightcore - NiceToMeetYou",
      "yourAccuracy": 96.46,
      "predictedAccuracy": 93.29899,
      "predictedAccuracyImprovement": -3.1610099999999903,
      "yourPP": 249.8,
      "predictedPP": 246.5175872565,
      "predictedPPImprovement": -3.2824127435000037
    },
    {
      "x": 248.47248,
      "y": 95.32,
      "songName": "166 - Suzuya Homerarete Nobiru Type Nandesu. - saradisk",
      "yourAccuracy": 95.32,
      "predictedAccuracy": 93.2684,
      "predictedAccuracyImprovement": -2.0515999999999934,
      "yourPP": 246.02,
      "predictedPP": 245.0882848224,
      "predictedPPImprovement": -0.9317151776000117
    },
    {
      "x": 245.79245,
      "y": 95.45,
      "songName": "Fallen (Short Ver.) - EGOIST",
      "yourAccuracy": 95.45,
      "predictedAccuracy": 93.20676,
      "predictedAccuracyImprovement": -2.24324,
      "yourPP": 245.16,
      "predictedPP": 242.2063381545,
      "predictedPPImprovement": -2.953661845499994
    },
    {
      "x": 245.30245,
      "y": 95.47,
      "songName": "ANiMA - Xi",
      "yourAccuracy": 95.47,
      "predictedAccuracy": 93.19549,
      "predictedAccuracyImprovement": -2.2745099999999923,
      "yourPP": 244.65,
      "predictedPP": 241.6793328135,
      "predictedPPImprovement": -2.970667186500009
    },
    {
      "x": 245.72245,
      "y": 96.02,
      "songName": "MariannE (Lanota Edit) - Yooh",
      "yourAccuracy": 96.02,
      "predictedAccuracy": 93.20515,
      "predictedAccuracyImprovement": -2.8148499999999927,
      "yourPP": 245.32,
      "predictedPP": 242.13244500550002,
      "predictedPPImprovement": -3.187554994499976
    },
    {
      "x": 244.99243,
      "y": 96.11,
      "songName": "Break - The Quick Brown Fox",
      "yourAccuracy": 96.11,
      "predictedAccuracy": 93.18836,
      "predictedAccuracyImprovement": -2.9216399999999965,
      "yourPP": 244.99,
      "predictedPP": 241.3469426416,
      "predictedPPImprovement": -3.6430573584
    },
    {
      "x": 240.9224,
      "y": 95.68,
      "songName": "Be Wild - Camellia feat. Yukacco",
      "yourAccuracy": 95.68,
      "predictedAccuracy": 93.09475,
      "predictedAccuracyImprovement": -2.585250000000002,
      "yourPP": 240.92,
      "predictedPP": 236.985727984,
      "predictedPPImprovement": -3.934272015999994
    },
    {
      "x": 237.67237,
      "y": 97.13,
      "songName": "Deadly Nostalgia - Zemeth",
      "yourAccuracy": -1,
      "predictedAccuracy": 93.01999,
      "predictedAccuracyImprovement": 94.01999,
      "yourPP": 208.17,
      "predictedPP": 233.5107268013,
      "predictedPPImprovement": 25.340726801300008
    },
    {
      "x": 236.60235,
      "y": 95.99,
      "songName": "Monochrome Butterfly - Aitsuki Nakuru",
      "yourAccuracy": 97.13,
      "predictedAccuracy": 92.99538,
      "predictedAccuracyImprovement": -4.134619999999998,
      "yourPP": 237.24,
      "predictedPP": 232.367167935,
      "predictedPPImprovement": -4.872832065000011
    },
    {
      "x": 235.53235,
      "y": 95.72,
      "songName": "Midnight - Teikyou",
      "yourAccuracy": 95.99,
      "predictedAccuracy": 92.97077,
      "predictedAccuracyImprovement": -3.019229999999993,
      "yourPP": 235.89,
      "predictedPP": 231.226818642,
      "predictedPPImprovement": -4.663181357999974
    },
    {
      "x": 235.55235,
      "y": 96.35,
      "songName": "Enantiomorphs - Camellia",
      "yourAccuracy": 95.72,
      "predictedAccuracy": 92.97123,
      "predictedAccuracyImprovement": -2.7487699999999933,
      "yourPP": 234.32,
      "predictedPP": 231.24645304199998,
      "predictedPPImprovement": -3.0735469580000085
    },
    {
      "x": 233.55233,
      "y": 96.35,
      "songName": "ParagonX9 - Infiltration - jackscape",
      "yourAccuracy": 96.35,
      "predictedAccuracy": 92.92523,
      "predictedAccuracyImprovement": -3.424769999999995,
      "yourPP": 234.97,
      "predictedPP": 229.11483573,
      "predictedPPImprovement": -5.855164269999989
    },
    {
      "x": 230.50229,
      "y": 95.15,
      "songName": "HONESTY - GYZE feat. Ettore Rigotti",
      "yourAccuracy": 96.35,
      "predictedAccuracy": 92.85508,
      "predictedAccuracyImprovement": -3.4949199999999934,
      "yourPP": 233.55,
      "predictedPP": 225.87149899389996,
      "predictedPPImprovement": -7.678501006100049
    },
    {
      "x": 231.5923,
      "y": 95.65,
      "songName": "Shera  - MYUKKE",
      "yourAccuracy": 95.15,
      "predictedAccuracy": 92.88015,
      "predictedAccuracyImprovement": -2.2698500000000053,
      "yourPP": 228.99,
      "predictedPP": 227.02993168999998,
      "predictedPPImprovement": -1.9600683100000253
    },
    {
      "x": 229.87229,
      "y": 96,
      "songName": "Stay Alive (Jun Kuroda Bootleg Remix) - EMILIA (CV: Rie Takahashi)",
      "yourAccuracy": 95.65,
      "predictedAccuracy": 92.84059,
      "predictedAccuracyImprovement": -2.8094099999999997,
      "yourPP": 230.1,
      "predictedPP": 225.2012850672,
      "predictedPPImprovement": -4.898714932800004
    },
    {
      "x": 227.76226,
      "y": 95.57,
      "songName": "Hype feat. Such - PSYQUI",
      "yourAccuracy": 96,
      "predictedAccuracy": 92.79206,
      "predictedAccuracyImprovement": -3.2079399999999936,
      "yourPP": 228.99,
      "predictedPP": 222.9610315592,
      "predictedPPImprovement": -6.028968440800014
    },
    {
      "x": 228.67228,
      "y": 96.69,
      "songName": "ouroboros -twin stroke of the end- - Cranky VS MASAKI",
      "yourAccuracy": 95.57,
      "predictedAccuracy": 92.81299,
      "predictedAccuracyImprovement": -2.757009999999994,
      "yourPP": 227.76,
      "predictedPP": 223.92733019,
      "predictedPPImprovement": -3.8326698099999987
    },
    {
      "x": 226.66226,
      "y": 95.46,
      "songName": "MAYDAY - TheFatRat",
      "yourAccuracy": 96.69,
      "predictedAccuracy": 92.76676,
      "predictedAccuracyImprovement": -3.9232399999999927,
      "yourPP": 228.4,
      "predictedPP": 221.79355465519998,
      "predictedPPImprovement": -6.606445344800022
    },
    {
      "x": 227.36227,
      "y": 95.21,
      "songName": "Engage-Rex- - Getty vs DJ DiA",
      "yourAccuracy": 95.46,
      "predictedAccuracy": 92.78286,
      "predictedAccuracyImprovement": -2.6771399999999943,
      "yourPP": 226.19,
      "predictedPP": 222.5376426306,
      "predictedPPImprovement": -3.6523573693999936
    },
    {
      "x": 222.36218,
      "y": 91.56,
      "songName": "Big Sky (Agnelli & Nelson Remix) - John O'Callaghan",
      "yourAccuracy": 95.21,
      "predictedAccuracy": 92.66786,
      "predictedAccuracyImprovement": -2.542139999999989,
      "yourPP": 224.76,
      "predictedPP": 217.24340261639998,
      "predictedPPImprovement": -7.516597383600015
    },
    {
      "x": 224.34224,
      "y": 96.66,
      "songName": "Everything will freeze - UNDEAD CORPORATION (mapped by Roffle)",
      "yourAccuracy": 91.56,
      "predictedAccuracy": 92.7134,
      "predictedAccuracyImprovement": 1.1533999999999907,
      "yourPP": 211.13,
      "predictedPP": 219.3371646256,
      "predictedPPImprovement": 8.2071646256
    },
    {
      "x": 223.73223,
      "y": 96.7,
      "songName": "Trusting - Merry (CV: Chika Anzai)",
      "yourAccuracy": 96.66,
      "predictedAccuracy": 92.69937,
      "predictedAccuracyImprovement": -3.9606299999999948,
      "yourPP": 224.08,
      "predictedPP": 218.69154285809998,
      "predictedPPImprovement": -5.388457141900034
    },
    {
      "x": 222.80222,
      "y": 96.02,
      "songName": "Zettai Tokken Shuchou Shimasu! Hoshi Syoko Solo Remix - Syoko Hoshi (CV: Satsumi Matsuda)",
      "yourAccuracy": 96.7,
      "predictedAccuracy": 92.67798,
      "predictedAccuracyImprovement": -4.022019999999998,
      "yourPP": 223.73,
      "predictedPP": 217.70673322860003,
      "predictedPPImprovement": -6.02326677139996
    },
    {
      "x": 219.85219,
      "y": 96.21,
      "songName": "Arcade Busters - cysmix",
      "yourAccuracy": 96.02,
      "predictedAccuracy": 92.61013,
      "predictedAccuracyImprovement": -3.409869999999998,
      "yourPP": 222.32,
      "predictedPP": 214.5911270933,
      "predictedPPImprovement": -7.728872906699991
    },
    {
      "x": 218.97218,
      "y": 97.03,
      "songName": "ACE FOR ACES - TAGxU1",
      "yourAccuracy": 96.21,
      "predictedAccuracy": 92.58989,
      "predictedAccuracyImprovement": -3.620109999999997,
      "yourPP": 219.85,
      "predictedPP": 213.6642943568,
      "predictedPPImprovement": -6.185705643199981
    },
    {
      "x": 218.65218,
      "y": 96.29,
      "songName": "Berry Go !! - Freezer (Feat. Kiichigo)",
      "yourAccuracy": 97.03,
      "predictedAccuracy": 92.58253,
      "predictedAccuracyImprovement": -4.447469999999996,
      "yourPP": 218.52,
      "predictedPP": 213.3258128952,
      "predictedPPImprovement": -5.194187104800022
    },
    {
      "x": 216.18216,
      "y": 95.67,
      "songName": "Star Night Snow (Short edit) - n-buna x Orangestar feat. Hatsune Miku",
      "yourAccuracy": 96.29,
      "predictedAccuracy": 92.52572,
      "predictedAccuracyImprovement": -3.7642799999999994,
      "yourPP": 217.72,
      "predictedPP": 210.72356046000002,
      "predictedPPImprovement": -6.996439539999983
    },
    {
      "x": 217.17217,
      "y": 96.73,
      "songName": "Inferno - 9mm Parabellum Bullet",
      "yourAccuracy": 95.67,
      "predictedAccuracy": 92.54849,
      "predictedAccuracyImprovement": -3.1215100000000007,
      "yourPP": 216.18,
      "predictedPP": 211.7667546887,
      "predictedPPImprovement": -4.413245311300017
    },
    {
      "x": 215.21214,
      "y": 95.21,
      "songName": "volcanic - DETRO",
      "yourAccuracy": 96.73,
      "predictedAccuracy": 92.50341,
      "predictedAccuracyImprovement": -4.226590000000002,
      "yourPP": 217.17,
      "predictedPP": 209.70270921600002,
      "predictedPPImprovement": -7.467290783999971
    },
    {
      "x": 211.9021,
      "y": 94.93,
      "songName": "CANDYYYLAND (Pa's Lam System Remix) - tofubeats",
      "yourAccuracy": 95.21,
      "predictedAccuracy": 92.42728,
      "predictedAccuracyImprovement": -2.7827199999999976,
      "yourPP": 198.24,
      "predictedPP": 206.225242741,
      "predictedPPImprovement": 7.985242740999979
    },
    {
      "x": 211.30211,
      "y": 96.72,
      "songName": "Sakura Reflection (P*Light Slayer Remix) - P*Light",
      "yourAccuracy": 94.93,
      "predictedAccuracy": 92.41348,
      "predictedAccuracyImprovement": -2.51652,
      "yourPP": 209.39,
      "predictedPP": 205.5948400089,
      "predictedPPImprovement": -3.795159991099979
    },
    {
      "x": 207.94207,
      "y": 96.51,
      "songName": "Barracuda - Noisestorm",
      "yourAccuracy": 96.72,
      "predictedAccuracy": 92.3362,
      "predictedAccuracyImprovement": -4.383799999999994,
      "yourPP": 211.3,
      "predictedPP": 202.0739447846,
      "predictedPPImprovement": -9.22605521540001
    },
    {
      "x": 207.70207,
      "y": 4,
      "songName": "Crying for Rain - Minami",
      "yourAccuracy": 96.51,
      "predictedAccuracy": 92.33068,
      "predictedAccuracyImprovement": -4.179320000000004,
      "yourPP": 206.19,
      "predictedPP": 201.824101419,
      "predictedPPImprovement": -4.36589858100001
    },
    {
      "x": 206.46206,
      "y": 95.51,
      "songName": "Koi no Hime Hime Pettanko - *namirin",
      "yourAccuracy": 4,
      "predictedAccuracy": 92.30216,
      "predictedAccuracyImprovement": 88.30216,
      "yourPP": 206.57,
      "predictedPP": 200.526275775,
      "predictedPPImprovement": -6.043724225000005
    },
    {
      "x": 203.68203,
      "y": 96.86,
      "songName": "World's End, Girl's Rondo (Asterisk DnB Remix) - Wakeshima Kanon",
      "yourAccuracy": 95.51,
      "predictedAccuracy": 92.23822,
      "predictedAccuracyImprovement": -3.271780000000007,
      "yourPP": 206.01,
      "predictedPP": 197.6224896075,
      "predictedPPImprovement": -8.38751039249999
    },
    {
      "x": 204.75204,
      "y": 91.56,
      "songName": "UNION (TV Size) - OxT",
      "yourAccuracy": 96.86,
      "predictedAccuracy": 92.26283,
      "predictedAccuracyImprovement": -4.5971700000000055,
      "yourPP": 192.18,
      "predictedPP": 198.74052010559998,
      "predictedPPImprovement": 6.560520105599977
    },
    {
      "x": 203.50201,
      "y": 96.97,
      "songName": "Snakey Chantey  - Kumi Tanioka",
      "yourAccuracy": 91.56,
      "predictedAccuracy": 92.23408,
      "predictedAccuracyImprovement": 0.6740800000000036,
      "yourPP": 190.68,
      "predictedPP": 197.4356150819,
      "predictedPPImprovement": 6.755615081900004
    },
    {
      "x": 202.66202,
      "y": 96.48,
      "songName": "Belly Flopper - O2i3",
      "yourAccuracy": 96.97,
      "predictedAccuracy": 92.21476,
      "predictedAccuracyImprovement": -4.755240000000001,
      "yourPP": 201.22,
      "predictedPP": 196.5578399576,
      "predictedPPImprovement": -4.662160042399989
    },
    {
      "x": 189.78186,
      "y": 97.46,
      "songName": "Break - The Quick Brown Fox",
      "yourAccuracy": 96.48,
      "predictedAccuracy": 91.91851,
      "predictedAccuracyImprovement": -4.561490000000006,
      "yourPP": 201.78,
      "predictedPP": 183.186940365,
      "predictedPPImprovement": -18.593059635000003
    },
    {
      "x": 195.69195,
      "y": 96.18,
      "songName": "HE4VEN  - Chart by Mystikmol",
      "yourAccuracy": 97.46,
      "predictedAccuracy": 92.05444,
      "predictedAccuracyImprovement": -5.405559999999994,
      "yourPP": 189.78,
      "predictedPP": 189.30652167149998,
      "predictedPPImprovement": -0.4734783285000219
    },
    {
      "x": 194.91194,
      "y": 90.31,
      "songName": "Pray(TV Size) - Chisuga Haruka",
      "yourAccuracy": 96.18,
      "predictedAccuracy": 92.0365,
      "predictedAccuracyImprovement": -4.143500000000003,
      "yourPP": 178,
      "predictedPP": 188.49738805459998,
      "predictedPPImprovement": 10.49738805459998
    },
    {
      "x": 190.6019,
      "y": 91.18,
      "songName": "Bomb Rush Blush (Smash Ultimate Mix) - Tomoya Ohtani",
      "yourAccuracy": 90.31,
      "predictedAccuracy": 91.93737,
      "predictedAccuracyImprovement": 1.627369999999999,
      "yourPP": 194.91,
      "predictedPP": 184.03375852599999,
      "predictedPPImprovement": -10.876241474000011
    },
    {
      "x": 190.28189,
      "y": 91.87,
      "songName": "Setsuna Imitation (feat. Yurica, Hanatan)  - Yairi",
      "yourAccuracy": -1,
      "predictedAccuracy": 91.93001,
      "predictedAccuracyImprovement": 92.93001,
      "yourPP": 177.18,
      "predictedPP": 183.7038450627,
      "predictedPPImprovement": 6.523845062699991
    },
    {
      "x": 182.20181,
      "y": 95.61,
      "songName": "Mirai to Mirai - nayutalien ft. Mirai Akari",
      "yourAccuracy": 91.18,
      "predictedAccuracy": 91.74417,
      "predictedAccuracyImprovement": 0.5641699999999901,
      "yourPP": 190.3,
      "predictedPP": 175.3728861612,
      "predictedPPImprovement": -14.927113838800011
    },
    {
      "x": 186.56186,
      "y": 92.67,
      "songName": "Sidetracked Day (Short Ver.) - VINXIS",
      "yourAccuracy": 91.87,
      "predictedAccuracy": 91.84445,
      "predictedAccuracyImprovement": -0.02555000000000973,
      "yourPP": 178.9,
      "predictedPP": 179.8624236074,
      "predictedPPImprovement": 0.9624236073999839
    },
    {
      "x": 190.0919,
      "y": 96.32,
      "songName": "City Lights (Pa's Lam System Remix) - HyperJuice",
      "yourAccuracy": 95.61,
      "predictedAccuracy": 91.92564,
      "predictedAccuracyImprovement": -3.684359999999998,
      "yourPP": 179.35,
      "predictedPP": 183.50711658400002,
      "predictedPPImprovement": 4.157116584000022
    },
    {
      "x": 182.48182,
      "y": 94.75,
      "songName": "Made In Love - Chart by Mystikmol",
      "yourAccuracy": 92.67,
      "predictedAccuracy": 91.75061,
      "predictedAccuracyImprovement": -0.919390000000007,
      "yourPP": 186.56,
      "predictedPP": 175.6606495684,
      "predictedPPImprovement": -10.899350431599999
    },
    {
      "x": 179.17178,
      "y": 91.61,
      "songName": "Rockefeller Street (Nightcore) - Getter Jaani",
      "yourAccuracy": 96.32,
      "predictedAccuracy": 91.67448,
      "predictedAccuracyImprovement": -4.6455199999999905,
      "yourPP": 190.01,
      "predictedPP": 172.26112444540001,
      "predictedPPImprovement": -17.748875554599977
    },
    {
      "x": 176.81176,
      "y": 6,
      "songName": "OKAY - Tokyo Machine",
      "yourAccuracy": 94.75,
      "predictedAccuracy": 91.6202,
      "predictedAccuracyImprovement": -3.129800000000003,
      "yourPP": 182.48,
      "predictedPP": 169.8418404208,
      "predictedPPImprovement": -12.638159579199993
    }
  ],
  "skillModelStr": "y = 0.023x + 87.55353"
}