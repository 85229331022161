const compareAscending = (a, b) => {
  if (typeof a !== typeof b) {
    throw new Error('MismatchedCompareTypes')
  }
  if (typeof a === 'number') {
    if (a < b) return -1
    if (a === b) return 0
    if (a > b) return 1
  }
  if (typeof a === 'string') {
    if (a < b) return -1
    if (a === b) return 0
    if (a > b) return 1
  }
  if (typeof a === 'object') {
    keyNameA = a.keyName
    keyNameB = b.keyName
    if (a[keyNameA] < b[keyNameB]) return -1
    if (a[keyNameA] === b[keyNameB]) return 0
    if (a[keyNameA] > b[keyNameB]) return 1
  }
  throw new Error('InvalidCompareType')
}

const compareDescending = (a, b) => {
  return compareAscending(a, b) * -1
}

const sortJsonByKey = (json, keyName, ascending) => {
  if (typeof json !== 'object') throw new Error('InvalidJson')

  json.forEach( x => { x.keyName = keyName })
  if (ascending === true)  json.sort(compareAscending)
  if (ascending === false) json.sort(compareDescending)
  json.forEach( x => { delete x.keyName })
  return json
}

module.exports = {sortJsonByKey}