const {renderScoresaberData} = require('./render.js')

const lambda = (profileId, env) => {
  const validInput = /^\d{16,17}$/ //exactly 16 or 17 digits
  if (!validInput.test(profileId) ) {
    const loadingIndicators = document.getElementsByClassName('ring-loader')
    for (let i = 0; i < loadingIndicators.length; i++) {
      loadingIndicators[i].innerHTML = '<p style="color: red;">Input Error: Invalid profile id</p>'
    }
  return Error("Input Error: Invalid profile id")
  }

  const apiUrl = `https://26v3d20at5.execute-api.us-east-2.amazonaws.com/${env}/?profileId=${profileId}`
  if (env === 'dev') console.log(apiUrl)

  fetch(apiUrl)
  .then(response => {
    return response.json()
  })
  .then(resJson =>  {
    renderScoresaberData(resJson)
  }).catch(e => {
    console.error(e)
    //Hide loading indicators
    const loadingIndicators = document.getElementsByClassName('ring-loader')
    for (let i = 0; i < loadingIndicators.length; i++) {
      loadingIndicators[i].innerHTML = '<p style="color: red;">Fetch Error: Reload Page</p>'
    }
  })
}

module.exports.lambda = lambda